export default price => {
  // const formatter = new Intl.NumberFormat('en-ID', {
  //   style: 'currency',
  //   currency: 'IDR',
  // })
  //   .format(typeof price === 'string' ? parseInt(price.replace(/,/g, ''), 10) : price)
  //   .replace(/[IDR]/gi, '')
  //   .replace(/(\.+\d{2})/, '')
  //   .trimLeft()

  // return formatter
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  })

  return formatter.format(price)
}
